// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null,
  tempOTP: null,
  userOrg: null,
  orgs: [],
  deviceDetail: {},
  userNotes: {
    data: [],
    total: 1,
    params: {},
  },
  userReminders: {
    data: [],
    total: 1,
    params: {},
  },
  userCustomers: {
    data: [],
    total: 1,
    params: {},
  },
  loaders: {
    user: false,
    orgs: false,
    deviceDetail: false,
    referrals: false,
    referredBy: false,
  },
  referrals: {
    data: [],
    total: 1,
    params: {},
  },
  referredBy: {
    data: [],
    total: 1,
    params: {},
  },
  referralCode: {},
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_USERS_DATA":
      return { ...state, allData: action.data };
    case "SEARCH_USERS_DATA":
      return {
        ...state,
        data: action.data,
        total: action.total,
        params: action.params,
      };
    case "GET_USER":
      return {
        ...state,
        selectedUser: action.selectedUser,
        userOrg: action.userOrg,
      };
    case "GET_USER_NOTES":
      return {
        ...state,
        userNotes: {
          ...state.userNotes,
          data: action.data,
          total: action.total,
          params: action.params,
        },
      };
    case "GET_USER_REMINDERS":
      return {
        ...state,
        userReminders: {
          ...state.userReminders,
          data: action.data,
          total: action.total,
          params: action.params,
        },
      };
    case "GET_USER_CUSTOMERS":
      return {
        ...state,
        userCustomers: {
          ...state.userCustomers,
          data: action.data,
          total: action.total,
          params: action.params,
        },
      };
    case "ADD_USER":
      return { ...state };
    case "DELETE_USER":
      return { ...state };
    case "TOGGLE_GET_USER_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, user: action.visiblity },
      };
    case "RESET_USER_INFO":
      return {
        ...state,
        selectedUser: null,
        userOrg: null,
      };
    case "GET_USER_ORGS":
      return {
        ...state,
        orgs: action.data,
      };
    case "RESET_USER_ORGS":
      return {
        ...state,
        orgs: [],
      };
    case "TOGGLE_GET_USER_ORGS_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, orgs: action.visiblity },
      };
    case "GET_USER_DEVICE_DETAILS":
      return {
        ...state,
        deviceDetail: action.data,
      };
    case "RESET_USER_DEVICE_DETAILS":
      return {
        ...state,
        deviceDetail: {},
      };
    case "TOGGLE_GET_USER_DEVICE_DETAILS_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, deviceDetail: action.visiblity },
      };
    case "SET_TEMP_OTP":
      return {
        ...state,
        tempOTP: action.data?.otp,
      };
    case "GET_USER_REFERRALS":
      return {
        ...state,
        referrals: {
          data: action.data,
          total: action.total,
          params: action.params,
        },
      };
    case "TOGGLE_GET_USER_REFERRALS_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, referrals: action.visiblity },
      };
    case "RESET_USER_REFERRALS":
      return {
        ...state,
        referrals: {
          data: [],
          total: 1,
          params: {},
        },
      };
    case "GET_USER_REFERRED_BY":
      return {
        ...state,
        referredBy: {
          data: action.data,
          total: action.total,
          params: action.params,
        },
      };
    case "TOGGLE_GET_USER_REFERRED_BY_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, referredBy: action.visiblity },
      };
    case "RESET_USER_REFERRED_BY":
      return {
        ...state,
        referredBy: {
          data: [],
          total: 1,
          params: {},
        },
      };
    case "RESET_USER_REFERRAL_CODE":
      return {
        ...state,
        referralCode: {},
      };
    case "GET_USER_REFERRAL_CODE":
      return {
        ...state,
        referralCode: action.data,
      };
    default:
      return { ...state };
  }
};
export default users;
